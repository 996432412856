import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";

import ListIcon from "@mui/icons-material/List";
import EditNoteIcon from "@mui/icons-material/EditNote";

import KioskRowErrorLink from "./KioskRowErrorLink";
import KioskRowSessionWorkflows from "./KioskRowSessionWorkflows";

import { getFrontendString } from "./../../../../utils/adminconsts";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";

function KioskRow({
  row,
  showDisabledEntries,
  handleActionViewKiosks,
  handleActionSetMode
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (showDisabledEntries == false && row.active == false) {
    return <></>;
  }

  return (
    <TableRow
      key={row.locationCode}
      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.locationCode}
      </TableCell>
      <TableCell
        align="left"
        style={row.active ? {} : { textDecoration: "line-through" }}
      >
        {row.subscriptionID}
      </TableCell>
      <TableCell align="left">
        <KioskRowErrorLink errorLogRef={row.errorLogRef} />
      </TableCell>
      <TableCell align="left">{row.counter}</TableCell>
      <TableCell align="left">
        {/* <IconButton
          onClick={() => {
            // Just temporary disabled
            // loadDetails(row._id);
            // setShowDetailsDialog(true);
          }}
        >
          {" "}
          <InfoIcon />
        </IconButton> */}
        {row.locationAddress}
      </TableCell>

      <TableCell align="left">{row.CreatedBy}</TableCell>
      <TableCell align="left">
        {formatDate(row.lastSynced, "YYYY-MMM-DD HH:mm:ss")}
      </TableCell>

      <TableCell align="left">
        {row.defaultWorkflowMode
          ? getFrontendString(row.defaultWorkflowMode)
          : "Bag and Labels"}
      </TableCell>

      <TableCell align="left">
        <KioskRowSessionWorkflows row={row} />
      </TableCell>

      {/* //Action button */}
      <TableCell align="center">
        <div>
          <Button
            variant="outlined"
            onClick={async () => {
              handleActionViewKiosks(row._id);
            }}
          >
            <SettingsIcon
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </Button>
          {/* <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button"
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={async () => {
                await handleClose();
                handleActionViewKiosks(row._id);
              }}
            >
              <ListIcon style={{ marginRight: ".5em" }} /> View Kiosks
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleActionSetMode(row._id);
              }}
            >
              <EditNoteIcon style={{ marginRight: ".5em" }} /> Set Workflow for
              All
            </MenuItem>
          </Menu> */}
        </div>
      </TableCell>

      <TableCell align="center">
        {row.active ? (
          <Circle style={{ color: "lightgreen" }} />
        ) : (
          <Circle style={{ color: "red" }} />
        )}
      </TableCell>
      {/* <TableCell align="center">
        <IconButton
          disabled={!row.active}
          onClick={() => {
            //setShowDeleteKeyDialog(true);
            //setKeyObjToDelete(row);
          }}
        >
          <Delete />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
}

export default KioskRow;
