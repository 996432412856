import * as React from "react";

import { getFrontendString } from "./../../../../utils/adminconsts";

function KioskRowSessionWorkflows({ row }) {
  console.log("KioskRowSessionWorkflows row", row);

  let workflowModeHash = {};

  row.kioskKeys.forEach(element => {
    if (element.kioskSettings) {
      if (!workflowModeHash[element.kioskSettings.workflowMode]) {
        workflowModeHash[element.kioskSettings.workflowMode] = 1;
      } else {
        workflowModeHash[element.kioskSettings.workflowMode]++;
      }
    } else {
      //Old format, doesn't have kioskSettings
      if (!workflowModeHash["[D] Bag and Labels"]) {
        workflowModeHash["[D] Bag and Labels"] = 1;
      } else {
        workflowModeHash["[D] Bag and Labels"]++;
      }
    }
  });

  // Object.keys(workflowModeHash).forEach(function (key) {
  //   var value = workflowModeHash[key]
  //   // iteration code
  // })

  return (
    <div>
      {Object.keys(workflowModeHash).map(key => (
        <div key={key}>
          <strong>{getFrontendString(key)}:</strong> {workflowModeHash[key]}
        </div>
      ))}
    </div>
  );
}

export default KioskRowSessionWorkflows;
