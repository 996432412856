// import * as React from "react";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";

import { formatDate } from "../../../../utils/misc";

import LocationObjectDisplay from "./LocationObjectDisplay";

import { Autocomplete, TextField, Snackbar, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AlertDialog from "../../../../components/Dialogs/AlertDialog";

import HttpContext from "../../../../contexts/HTTP/HttpContext";

function CreateKioskGroupDialog(props) {
  const theme = useTheme();
  const { post, get } = useContext(HttpContext);

  const [locationcode, setLocationcode] = useState("");
  const [locationdescription, setLocationdescription] = useState("");

  const [locationobject, setLocationobject] = useState(null);

  const {
    row,
    setShowDeleteKioskDialog,
    setKioskObjectToDelete,
    open,
    handleConfirm,
    handleClose,
    disableConfirm,
    value,
    onChange,
    error,
    helperText
  } = props;

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [snackbaropen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    async function getDropLoc() {
      const res = await get("/kiosk/getDropLocations");
      if (res.status === 200) {
        console.log("GOT 200 from getDropLocations res.data", res.data);
        //TODO: Check format of data
        setLocationOptions(res.data.dropLocations);

        let ll = res.data.dropLocations.map(location => location.code);
        console.log("ll", ll);
        setLocationList(ll);
      }
    }

    getDropLoc();
  }, []);

  const changeLocationCombo = async (evt, newlocation) => {
    console.log("changeLocationCombo newlocation", newlocation);

    //Find info from locationOptions
    let locationOption = locationOptions.find(l => {
      return l.code == newlocation;
    });
    console.log("changeLocationCombo locationOption", locationOption);
    setLocationobject(locationOption);

    setLocationcode(newlocation);
  };

  const generateKioskGroup = async () => {
    props.setLoading(true);

    //console.log("locationobject", locationobject);

    const res = await post("/kiosk/createSubscription", {
      schemaVersion: 3,
      maxKiosks: -1,
      locationCode: locationobject.code,
      locationName: locationobject.name,
      locationAddress: locationobject.address,
      subscriptionID: locationobject.key,
      //locationDescription: locationobject.,
      kioskKeys: []
    });
    if (res.status === 200) {
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setSnackbarMessage("Added successfully");
    } else {
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setSnackbarMessage("Duplicate entry - already exists");
    }

    await props.loadKeys();

    props.setLoading(false);
    handleClose();
  };

  //TODO: Move this into a provider?
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <AlertDialog
        open={open}
        handleConfirm={generateKioskGroup}
        handleClose={handleClose}
        title="Add a Kiosk Location"
        confirmButtonName="Confirm"
        // disableConfirm={disableConfirm}
      >
        <br />
        <Autocomplete
          disablePortal
          id="cblocationoptions"
          options={locationList}
          onChange={changeLocationCombo}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              name="name"
              label="Location Code"
              style={{ marginBottom: theme.spacing(2) }}
            />
          )}
        />
        <LocationObjectDisplay Locationobject={locationobject} />
        {/* <TextField
        fullWidth
        variant="outlined"
        id="name"
        name="name"
        label="Location String"
        placeholder="the 5th street and 4th ave location"
        value={locationdescription}
        onChange={e => setLocationdescription(e.target.value)}
        style={{ marginBottom: theme.spacing(2) }}
      /> */}
      </AlertDialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbaropen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateKioskGroupDialog;
