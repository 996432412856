import React, { useEffect, useContext, useState } from "react";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import { Route, Routes, Navigate } from "react-router-dom";

import NonProdRoute from "./Kiosk/Pages/NonProdRoute";

import Login from "./Admin/Login/Login";
import ForgetPassword from "./Admin/Login/ForgetPassword";
import ForgetPasswordSent from "./Admin/Login/ForgetPasswordSent";
import ResetPassword from "./Admin/Login/ResetPassword";
import ResetPasswordDone from "./Admin/Login/ResetPasswordDone";
import Register from "./Admin/Register/Register";
import Dashboard from "./Admin/Dashboard/Dashboard";

import AConfiguration from "./Kiosk/AConfiguration";
import ALanguageSelect from "./Kiosk/ALanguageSelect";
import CustomerPhoneNumber from "./Kiosk/CustomerPhoneNumber";
import NumberOfBags from "./Kiosk/NumberOfBags";
import NumberOfLabels from "./Kiosk/NumberOfLabels";
import PrintLabels from "./Kiosk/PrintLabels";
import ThankYou from "./Kiosk/ThankYou";
import ErrorScreen from "./Kiosk/ErrorScreen";

import PrinterDebug from "./Kiosk/PrinterDebug";
import DebugSocketIO from "./Kiosk/DebugSocketIO";

import { CircularProgress, Grid } from "@mui/material";

import AuthContext from "../contexts/Auth/AuthContext";
import HttpContext from "../contexts/HTTP/HttpContext";
import MobileContext from "../contexts/Mobile/MobileContext";

import KioskContext from "../contexts/Kiosk/KioskContext";

import useMobile from "../hooks/useMobile";

function Pages() {
  const { get } = useContext(HttpContext);
  const { authenticated, setAuthenticated } = useContext(AuthContext);
  const { setIsMobile } = useContext(MobileContext);

  // const {
  //   customerName,
  //   customerID,
  //   setCustomerNameAndID,
  //   numBags,
  //   setNumBags,
  //   numLabels,
  //   setLabels,
  //   customerReset
  // } = useContext(KioskContext);

  const [loading, setLoading] = useState(true);

  const {} = useMobile({ setIsMobile });

  const handleAuthentication = res => {
    // Handles authentication responses
    const accessToken = _.get(res, "data.accessToken");
    if (!accessToken) return;

    localStorage.setItem("accessToken", accessToken);
    setAuthenticated(generateAuthenticatedObjFromToken(accessToken));
  };

  useEffect(() => {
    // Hook to handle persistent logins
    if (authenticated) return;

    setLoading(true);

    (async () => {
      const res = await get("/checkStatus");
      if (res.status === 200) {
        const accessToken = localStorage.getItem("accessToken");
        setAuthenticated(generateAuthenticatedObjFromToken(accessToken));
      }

      setLoading(false);
    })();
  }, [authenticated]);

  if (loading) {
    return (
      <Grid
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (authenticated) {
    return (
      <Routes>
        <Route index element={<Navigate to="/dashboard/kiosks" replace />} />
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route path="*" element={<Navigate to="/dashboard/kiosks" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route index element={<Navigate to="/configuration" replace />} />
      <Route
        path="login"
        element={<Login authenticate={handleAuthentication} />}
      />
      <Route
        path="forgetpassword"
        element={<ForgetPassword authenticate={handleAuthentication} />}
      />
      <Route
        path="forgetpasswordsent"
        element={<ForgetPasswordSent authenticate={handleAuthentication} />}
      />
      <Route
        exact
        path="reset/:id/:token"
        element={<ResetPassword authenticate={handleAuthentication} />}
      />
      <Route
        exact
        path="resetpassworddone"
        element={<ResetPasswordDone authenticate={handleAuthentication} />}
      />
      <Route
        path="register"
        element={
          <NonProdRoute
            Component={<Register authenticate={handleAuthentication} />}
            redirect="/configuration"
          />
        }
      />
      <Route
        path="configuration"
        element={<AConfiguration authenticate={handleAuthentication} />}
      />
      <Route
        path="language"
        element={<ALanguageSelect authenticate={handleAuthentication} />}
      />
      <Route
        path="phonenumber"
        element={<CustomerPhoneNumber authenticate={handleAuthentication} />}
      />
      <Route
        path="numbags"
        element={<NumberOfBags authenticate={handleAuthentication} />}
      />
      <Route
        path="numlabels"
        element={<NumberOfLabels authenticate={handleAuthentication} />}
      />
      <Route
        path="printlabels"
        element={<PrintLabels authenticate={handleAuthentication} />}
      />
      <Route
        path="thanksyou"
        element={<ThankYou authenticate={handleAuthentication} />}
      />
      <Route
        path="errorscreen"
        element={<ErrorScreen authenticate={handleAuthentication} />}
      />
      <Route
        path="printerdebug"
        element={<PrinterDebug authenticate={handleAuthentication} />}
      />
      <Route
        path="debugsocketio"
        element={<DebugSocketIO authenticate={handleAuthentication} />}
      />
      <Route path="*" element={<Navigate to="/configuration" replace />} />
    </Routes>
  );
}

export default Pages;

function generateAuthenticatedObjFromToken(token) {
  const decodedToken = jwtDecode(token);

  return { roles: decodedToken.roles };
}
