import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";

import FormControl from "@mui/material/FormControl";

import { useTheme } from "@mui/material/styles";

import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerLoading from "../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../utils/misc";

import UpdateLogRow from "./SyncUpdateLogs/UpdateLogRow";

import EditDialog from "./Configuration/EditDialog";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

import moment from "moment";

import { WORKFLOW_MODES } from "../../../constants";

function ServerSettings() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [editDialogIsOpened, setEditDialogIsOpened] = useState(false);
  const [editDialogData, setEditDialogData] = useState(null);
  const [editDialogIndex, setEditDialogIndex] = useState(null);

  const [serverSetting, setServerSetting] = useState(null);

  const loadData = async () => {
    setLoading(true);

    //Load data
    const res2 = await get("/kiosk/getServerSetting/ConsignAction");

    if (res2.status === 401) {
      console.log("ERROR, please relog in");
    }

    if (res2.status === 200) {
      const data = _.get(res2, "data");

      await setServerSetting(data);
    }

    setLoading(false);
  };

  const handleUpdateSettings = async settings => {
    let payload = { _id: serverSetting._id, settings: settings };

    console.log("payload", payload);

    const res2 = await post("/kiosk/serverSetting/ConsignAction", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //SInce format can change, just reload from server
      await loadData();

      return subscriptionData;
    }
  };

  const EditSettings = index => {
    console.log("Configuration.EditSettings() index", index);
    setEditDialogIndex(index);
    setEditDialogData(setServerSetting);
    setEditDialogIsOpened(true);
  };

  const loadDetails = async id => {};

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">Server Settings</Typography>
          <Typography>View and edit your server settings</Typography>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => loadData()}
            disabled={loading}
          >
            <RefreshIcon style={{ marginRight: theme.spacing(1) }} />
            Refresh
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            {/* <TableRow>
              <TableCell align="left">Configuration Name</TableCell>
              <TableCell align="left">Collector Code</TableCell>
              <TableCell align="left">Last Updated</TableCell>
              <TableCell align="left">Settings</TableCell>
            </TableRow> */}
          </TableHead>

          {/* {configurationList.settings.map((row, index) => ( */}
          {serverSetting !== undefined && serverSetting !== null ? (
            <TableBody>
              {/* <TableRow>
                <TableCell align="left">Enable Web Client</TableCell>
                <TableCell align="left">
                  <Checkbox
                    size="small"
                    checked={serverSetting.settings.enabledWeb}
                  />
                </TableCell>
              </TableRow> */}

              <TableRow>
                <TableCell align="center">Default New Kiosk Workflow</TableCell>
                <TableCell align="center">
                  {/* //Batch Update */}
                  <FormControl sx={{ m: 1, minWidth: 230 }}>
                    {/* 
                    <InputLabel id="batch-update-select-label">
                      Default New Kiosk Workflow
                    </InputLabel>
                    */}
                    <Select
                      labelId="batch-update-open-select-label"
                      id="batch-update-open-select"
                      //label="Workflow Mode"
                      value={serverSetting.settings.defaultNewKioskWorkflow}
                      onChange={async event => {
                        handleUpdateSettings({
                          ...serverSetting.settings,
                          defaultNewKioskWorkflow: event.target.value
                        });
                        // let results = await handleSetWorkflowModeSubscription(
                        //   subscriptions._id,
                        //   event.target.value
                        // );
                        // console.log("KioskListDialog.js results from update", results);
                        // setSubscriptions(results);
                      }}
                    >
                      <MenuItem value={WORKFLOW_MODES.BAGS_AND_LABELS}>
                        Bag and Labels
                      </MenuItem>
                      <MenuItem value={WORKFLOW_MODES.BAG_ONLY}>
                        Bags Only
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>Loading</TableBody>
          )}
          {/* ))} */}
        </Table>
      </TableContainer>
      <EditDialog
        open={editDialogIsOpened}
        editDialogData={editDialogData}
        editDialogIndex={editDialogIndex}
        handleClose={() => {
          setEditDialogIsOpened(false);
        }}
      />
    </Grid>
  );
}

export default ServerSettings;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
