import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TextField
} from "@mui/material";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";

import WorkflowDropdown from "./KiosksListDialog/WorkflowDropdown";
import KiosksListTableBody from "./KiosksListDialog/KiosksListTableBody";

import { useTheme } from "@mui/material/styles";

import { WORKFLOW_MODES } from "../../../../constants";

export default function KioskListDialog({
  subscription,
  open,
  handleSetWorkflowModeKiosk,
  handleClose,
  handleSetWorkflowModeSubscription,
  handleSetDefaultWorkflowModeSubscription
}) {
  const theme = useTheme();

  const [filterKioskID, setFilterKioskID] = useState("");
  const [subscriptions, setSubscriptions] = useState(subscription);


  useEffect(() => {
    setSubscriptions(subscription);
  }, [subscription]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-kiosklist"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <DialogTitle id="modal-kiosklist">
            Device List for {subscriptions ? subscriptions.locationName : ""} [
            {subscriptions ? subscriptions.locationCode : ""}]
          </DialogTitle>

          {/* Default Workflow*/}
          <FormControl sx={{ m: 1, minWidth: 230 }}>
            <InputLabel id="default-workflow-select-label">
              New Kiosk Workflow
            </InputLabel>
            <Select
              labelId="default-workflow-open-select-label"
              id="default-workflow-open-select"
              label="Workflow Mode"
              value={
                subscriptions && subscriptions.defaultWorkflowMode
                  ? subscriptions.defaultWorkflowMode
                  : WORKFLOW_MODES.BAGS_AND_LABELS
              }
              onChange={async event => {
                let results = await handleSetDefaultWorkflowModeSubscription(
                  subscriptions._id,
                  event.target.value
                );

                console.log("KioskListDialog.js results from update", results);
                setSubscriptions(results);
              }}
            >
              <MenuItem value={WORKFLOW_MODES.BAGS_AND_LABELS}>
                Bag and Labels
              </MenuItem>
              <MenuItem value={WORKFLOW_MODES.BAG_ONLY}>Bags Only</MenuItem>
            </Select>
          </FormControl>

          {/* //Batch Update */}
          <FormControl sx={{ m: 1, minWidth: 230 }}>
            <InputLabel id="batch-update-select-label">
              Batch update all workflow
            </InputLabel>
            <Select
              labelId="batch-update-open-select-label"
              id="batch-update-open-select"
              label="Workflow Mode"
              onChange={async event => {
                let results = await handleSetWorkflowModeSubscription(
                  subscriptions._id,
                  event.target.value
                );

                console.log("KioskListDialog.js results from update", results);
                setSubscriptions(results);
              }}
            >
              <MenuItem value={WORKFLOW_MODES.BAGS_AND_LABELS}>
                Bag and Labels
              </MenuItem>
              <MenuItem value={WORKFLOW_MODES.BAG_ONLY}>Bags Only</MenuItem>
            </Select>
          </FormControl>
        </div>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content"
            }}
          >
            <TextField
              id="ks"
              label="Kiosk ID Search"
              value={filterKioskID}
              onChange={event => {
                let newText = event.target.value;
                setFilterKioskID(newText);
              }}
              style={{ marginTop: "1em" }}
              size="small"
            />

            <TableContainer>
              <Table
                style={{
                  marginTop: theme.spacing(2)
                  //display: loading ? "none" : null
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Kiosk ID</TableCell>
                    <TableCell align="left">Created IP</TableCell>
                    <TableCell align="left">Workflow Mode</TableCell>
                    {/* <TableCell align="left">Bagtags</TableCell>
                    <TableCell align="left">Logs</TableCell> */}
                    <TableCell align="left">Last Accessed</TableCell>
                    <TableCell align="left">Created</TableCell>
                  </TableRow>
                </TableHead>
                <KiosksListTableBody
                  subscriptions={subscriptions}
                  filterKioskID={filterKioskID}
                  handleSetWorkflowModeKiosk={handleSetWorkflowModeKiosk}
                />
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
